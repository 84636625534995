import { lazy } from "react"

const Settings = lazy(() => import("../views/settings/Index"))
const Status = lazy(() => import("../views/status/Index"))
const StatusCreate = lazy(() => import("../views/status/Create"))
const Application = lazy(() => import("../views/application/Index"))
const ApplicationCreate = lazy(() => import("../views/application/Create/index"))
const Property = lazy(() => import("../views/property/Index"))
const PropertyCreate = lazy(() => import("../views/property/Create"))
const GroupProperty = lazy(() => import("../views/groupProperty/index"))
const CreateGroupProperty = lazy(() => import("../views/groupProperty/Create"))
const NewOffers = lazy(() => import("../views/new-offers/Index"))
const NewOffersView = lazy(() => import("../views/new-offers/View"))

const Dashboard = lazy(() => import("../views/dashboard/index"))

const Organizations = lazy(() => import("../views/organizations/Index.jsx"))
const Governor = lazy(() => import("../views/governor/Index.jsx"))
const GovernorCreate = lazy(() => import("../views/governor/Create/index"))
// Cabinet
const GeoServiceCabinet = lazy(() => import("../views/geoServiceCabinet/Index.jsx"))
const GeoServiceCabinetCreate = lazy(() => import("../views/geoServiceCabinet/Create/index"))

const TaxListDistrict = lazy(() => import("../views/taxlist_district/Index"))
const TaxListTax = lazy(() => import("../views/taxlist_tax/Index"))
const Reports = lazy(() => import("../views/reports/Index"))
const EditDraft = lazy(() => import("../views/application/EditDraft"))
const OrganizationsCreate = lazy(() => import("../views/organizations/Create/index"))
const Roles = lazy(() => import("../views/roles/Index.jsx"))
const RolesCreate = lazy(() => import("../views/roles/Create"))
const Permission = lazy(() => import("../views/permission"))
const PermissionCreate = lazy(() => import("../views/permission/Create"))
const OpenMap = lazy(() => import("../views/openMap/index"))
const Users__Premises = lazy(() => import("../views/users/premises"))
const Users__PremisesCreate = lazy(() => import("../views/users/premises/Create"))
const Branch = lazy(() => import("../views/branch/Index"))
const BranchCreate = lazy(() => import("../views/branch/Create/index"))
const DiagramList = lazy(() => import("../views/diagrams/index.jsx"))
const SystemSettings = lazy(() => import("../views/SystemSettings/index.jsx"))
const Announcement = lazy(() => import("../views/announcement/index.jsx"))
const Cities = lazy(() => import("../views/cities"))
const CitiesCreate = lazy(() => import("../views/cities/Create.jsx"))
const Districts = lazy(() => import("../views/districts"))
const District = lazy(() => import("../views/district"))
const DistrictsCreate = lazy(() => import("../views/districts/Create"))
const CountyCreate = lazy(() => import("../views/districts/Create/CountyCreate"))
const Profile = lazy(() => import("../views/profile"))
const ConstructionObject = lazy(() => import("../views/constructionObject/index"))
const ConstructionObjectCreate = lazy(() => import("../views/constructionObject/Create"))
const Applicant = lazy(() => import("../views/applicant/index"))
const ApplicantView = lazy(() => import("../views/applicant/View"))
const EntityType = lazy(() => import("../views/entityType/index"))
const EntityTypeCreate = lazy(() => import("../views/entityType/Create"))
const ClosedAuction = lazy(() => import("../views/closedAuction/index"))
const ClosedAuctionModulEdit = lazy(() => import("../views/closedAuction/CreateAuction.js"))
const ClosedAuctionModuls = lazy(() => import("../views/closedAuction/IndexModul.js"))
const ClosedAuctionCreateEntity = lazy(() => import("../views/closedAuction/CreateAuctionEntity.js"))

const News = lazy(() => import("../views/news"))
const NewsCreate = lazy(() => import("../views/news/Create"))
const NewsUpdate = lazy(() => import("../views/news/Update"))

const Handbook = lazy(() => import("../views/handbook"))
const HandbookCreate = lazy(() => import("../views/handbook/Create"))
const HandbookUpdate = lazy(() => import("../views/handbook/Update"))
const Docs = lazy(() => import("../views/docs"))
const DocsCreate = lazy(() => import("../views/docs/Create"))
const DocsUpdate = lazy(() => import("../views/docs/Update"))
const Reload = lazy(() => import("../views/reload"))
//////////////////////////////////////////////////////
const Tutorial = lazy(() => import("../views/tutorial/index"))
const FAQs = lazy(() => import("../views/faqs"))
const Pricing = lazy(() => import("../views/pricing/Pricing.js"))

export default [
  {
    component: Dashboard,
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    permission: "dashboard",
  },
  {
    component: FAQs,
    path: "/faqs",
    exact: true,
    title: "FAQs",
    permission: "dashboard",
  },
  {
    component: Reports,
    path: "/reports",
    exact: true,
    title: "Reports",
    permission: "FAQ",
  },

  {
    component: TaxListTax,
    path: "/taxlist/tax",
    exact: true,
    title: "TaxList",
    permission: "tax_rate",
  },
  {
    component: TaxListDistrict,
    path: "/taxlist/district",
    exact: true,
    title: "TaxList",
    permission: "tax_district",
  },

  {
    component: NewOffers,
    path: "/new-offers",
    exact: true,
    title: "NewOffers",
    permission: "new_offers",
  },

  {
    component: NewOffersView,
    path: "/new-offers/:id",
    exact: true,
    title: "NewOffersView",
    permission: "new_offers_view",
  },
  {
    component: Settings,
    path: "/settings",
    exact: true,
    title: "Settings",
    permission: "settings",
  },
  {
    component: Status,
    path: "/settings/status",
    exact: true,
    title: "Status",
    permission: "settings_status",
  },
  {
    component: StatusCreate,
    path: "/settings/status/create/:tabNum",
    exact: true,
    title: "StatusCreate",
    permission: "settings_status_create",
  },
  {
    component: StatusCreate,
    path: "/settings/status/:id",
    exact: true,
    title: "StatusEdit",
    permission: "settings_status_edit",
  },
  {
    component: ApplicationCreate,
    path: "/application/:typeCode/:diagramId/create",
    // exact: true,
    title: "ApplicationCreate",
    permission: "application_create",
  },
  {
    component: Application,
    path: "/application/:typeCode/:diagramId",
    // exact: true,
    title: "Application",
    permission: "application",
  },
  {
    component: EditDraft,
    path: "/application/draft/:id",
    exact: true,
    title: "ApplicationEdit",
    permission: "application_draft_edit",
  },
  {
    component: ApplicationCreate,
    path: "/application/:typeCode/:diagramId/edit/:id",
    // exact: true,
    title: "ApplicationEdit",
    permission: "application_edit",
  },
  {
    component: Organizations,
    path: "/settings/organizations",
    exact: true,
    title: "Organizations",
    permission: "settings_organization",
  },
  {
    component: Governor,
    path: "/settings/governor",
    exact: true,
    title: "Governor",
    permission: "settings_governor",
  },
  {
    component: GovernorCreate,
    path: "/settings/governor/create",
    exact: true,
    title: "Governor",
    permission: "settings_governor",
  },
  {
    component: GovernorCreate,
    path: "/settings/governor/:id",
    exact: true,
    title: "GovernorEdit",
    permission: "settings_governor",
  },
  {
    component: GeoServiceCabinet,
    path: "/settings/geo-service-cabinet",
    exact: true,
    title: "GeoServiceCabinet",
    permission: "settings_geo_service_cabinet",
  },
  {
    component: GeoServiceCabinetCreate,
    path: "/settings/geo-service-cabinet/create",
    exact: true,
    title: "Governor",
    permission: "settings_geo_service_cabinet",
  },
  {
    component: GeoServiceCabinetCreate,
    path: "/settings/geo-service-cabinet/:id",
    exact: true,
    title: "GovernorEdit",
    permission: "settings_geo_service_cabinet",
  },

  {
    component: OrganizationsCreate,
    path: "/settings/organizations/:checker/create",
    exact: true,
    title: "OrganizationsCreate",
    permission: "settings_organization_create",
  },

  {
    component: OrganizationsCreate,
    path: "/settings/organizations/:id/:tableType",
    exact: true,
    title: "OrganizationsEdit",
    permission: "settings_organization_edit",
  },

  {
    component: Cities,
    path: "/settings/regions",
    exact: true,
    title: "Cities",
    permission: "settings_city",
  },

  {
    component: CitiesCreate,
    path: "/settings/regions/create",
    exact: true,
    title: "CitiesCreate",
    permission: "settings_city_create",
  },

  {
    component: CitiesCreate,
    path: "/settings/regions/:id",
    exact: true,
    title: "CitiesEdit",
    permission: "settings_city_edit",
  },

  {
    component: Districts,
    path: "/settings/districts",
    exact: true,
    title: "Districts",
    permission: "settings_city",
  },
  {
    component: District,
    path: "/settings/district",
    exact: true,
    title: "District",
    permission: "settings_city",
  },
  {
    component: DistrictsCreate,
    path: "/settings/districts/create",
    exact: true,
    title: "DistrictsCreate",
    permission: "settings_city",
  },

  {
    component: DistrictsCreate,
    path: "/settings/districts/:id",
    exact: true,
    title: "DistrictsEdit",
    permission: "settings_city",
  },

  {
    component: CountyCreate,
    path: "/settings/county/create",
    exact: true,
    title: "CountyCreate",
    permission: "settings_city",
  },

  {
    component: CountyCreate,
    path: "/settings/county/:id",
    exact: true,
    title: "CountyEdit",
    permission: "settings_city",
  },

  {
    component: Branch,
    path: "/settings/branches",
    exact: true,
    title: "Branchs",
    permission: "settings_branchs",
  },

  {
    component: BranchCreate,
    path: "/settings/branches/create",
    exact: true,
    title: "BranchCreate",
    permission: "settings_branchs_create",
  },

  {
    component: BranchCreate,
    path: "/settings/branches/:id",
    exact: true,
    title: "BranchEdit",
    permission: "settings_branchs_edit",
  },

  {
    component: Roles,
    path: "/settings/roles",
    exact: true,
    title: "Roles",
    permission: "settings_roles",
  },
  {
    component: RolesCreate,
    path: "/settings/roles/create",
    exact: true,
    title: "RolesCreate",
    permission: "settings_roles_create",
  },
  {
    component: RolesCreate,
    path: "/settings/roles/:id",
    exact: true,
    title: "RolesEdit",
    permission: "settings_roles_edit",
  },
  {
    component: Property,
    path: "/settings/property",
    exact: true,
    title: "Property",
    permission: "settings_property",
  },
  {
    component: PropertyCreate,
    path: "/settings/property/create",
    exact: true,
    title: "PropertyCreate",
    permission: "settings_property_create",
  },
  {
    component: PropertyCreate,
    path: "/settings/property/:id",
    exact: true,
    title: "PropertyEdit",
    permission: "settings/property_edit",
  },
  {
    component: GroupProperty,
    path: "/settings/group-property",
    exact: true,
    title: "GroupProperty",
    permission: "settings_group_property",
  },
  {
    component: CreateGroupProperty,
    path: "/settings/group-property/create",
    exact: true,
    title: "CreateGroupProperty",
    permission: "settings_group_property_create",
  },
  {
    component: CreateGroupProperty,
    path: "/settings/group-property/:id",
    exact: true,
    title: "CreateGroupProperty",
    permission: "settings_group_property_edit",
  },
  {
    component: Permission,
    path: "/settings/permission",
    exact: true,
    title: "Permission",
    permission: "settings_permission",
  },
  {
    component: PermissionCreate,
    path: "/settings/permission/create",
    exact: true,
    title: "CreatePermission",
    permission: "settings_permission_create",
  },
  {
    component: PermissionCreate,
    path: "/settings/permission/:id",
    exact: true,
    title: "CreatePermission",
    permission: "settings_permission_edit",
  },
  {
    component: ConstructionObject,
    path: "/settings/construction-object",
    exact: true,
    title: "ContructionObject",
    permission: "contruction_object",
  },

  {
    component: ConstructionObjectCreate,
    path: "/settings/construction-object/create",
    exact: true,
    title: "ContructionObjectCreate",
    permission: "construction_object_create",
  },

  {
    component: ConstructionObjectCreate,
    path: "/settings/construction-object/:id",
    exact: true,
    title: "ContructionObjectEdit",
    permission: "contruction_object_edit",
  },

  {
    component: EntityType,
    path: "/settings/entity-type",
    exact: true,
    title: "EntityType",
    permission: "entity_type",
  },

  {
    component: EntityTypeCreate,
    path: "/settings/entity-type/create",
    exact: true,
    title: "EntityTypeCreate",
    permission: "entity-type_create",
  },

  {
    component: EntityTypeCreate,
    path: "/settings/entity-type/:id",
    exact: true,
    title: "EntityTypeEdit",
    permission: "entity-type_edit",
  },
  {
    component: ClosedAuction,
    path: "/settings/auctions",
    exact: true,
    title: "Auksion",
    permission: "closed_auction",
  },
  {
    component: ClosedAuctionCreateEntity,
    path: "/settings/auctions/create",
    exact: true,
    title: "Auksion",
    permission: "closed_auction",
  },
  {
    component: ClosedAuctionCreateEntity,
    path: "/settings/auctions/:id",
    exact: true,
    title: "Auksion",
    permission: "closed_auction",
  },
  {
    component: ClosedAuctionModuls,
    path: "/settings/auctions-setting",
    exact: true,
    title: "Auksion",
    permission: "closed_auction",
  },
  {
    component: ClosedAuctionModulEdit,
    path: "/settings/auctions-setting/:id",
    exact: true,
    title: "Auksion",
    permission: "closed_auction",
  },
  {
    component: DiagramList,
    path: "/settings/diagram-list",
    exact: true,
    title: "DiagramList",
    permission: "settings_permission_diagram",
  },
  {
    component: SystemSettings,
    path: "/settings/system-settings",
    exact: true,
    title: "SystemSettings",
    permission: "settings_permission_system",
  },
  {
    component: Announcement,
    path: "/settings/announcement",
    exact: true,
    title: "Announcement",
    permission: "settings_permission_system",
  },
  {
    component: OpenMap,
    path: "/open-map",
    exact: true,
    title: "openMap",
    permission: "open_map",
  },
  {
    component: Tutorial,
    path: "/tutorial",
    exact: true,
    title: "Tutorial",
    permission: "application",
  },

  {
    component: Users__Premises,
    path: "/users/premises",
    exact: true,
    title: "Users__Premises",
    permission: "users_premises",
  },

  {
    component: Users__PremisesCreate,
    path: "/users/premises/create",
    exact: true,
    title: "Users__PremisesCreate",
    permission: "users_premises_create",
  },

  {
    component: Users__PremisesCreate,
    path: "/users/premises/:id",
    exact: true,
    title: "Users__PremisesEdit",
    permission: "users_premises_edit",
  },

  {
    component: Applicant,
    path: "/users/applicant",
    exact: true,
    title: "Applicant",
    permission: "users_applicant",
  },
  {
    component: News,
    path: "/content/news",
    exact: true,
    title: "News",
    permission: "content",
  },
  {
    component: NewsCreate,
    path: "/content/news/create",
    exact: true,
    title: "NewsCreate",
    permission: "content",
  },
  {
    component: NewsUpdate,
    path: "/content/news/:slug",
    exact: true,
    title: "NewsUpdate",
    permission: "content",
  },
  // Handbook
  {
    component: Handbook,
    path: "/content/handbook",
    exact: true,
    title: "News",
    permission: "content",
  },
  {
    component: HandbookCreate,
    path: "/content/handbook/create",
    exact: true,
    title: "NewsCreate",
    permission: "content",
  },
  {
    component: HandbookUpdate,
    path: "/content/handbook/:id",
    exact: true,
    title: "NewsUpdate",
    permission: "content",
  },

  {
    component: Docs,
    path: "/content/docs",
    exact: true,
    title: "Docs",
    permission: "content",
  },
  {
    component: DocsCreate,
    path: "/content/docs/create",
    exact: true,
    title: "DocsCreate",
    permission: "content",
  },
  {
    component: DocsUpdate,
    path: "/content/docs/:slug",
    exact: true,
    title: "DocsUpdate",
    permission: "content",
  },

  {
    component: ApplicantView,
    path: "/users/applicant/:id",
    exact: true,
    title: "ApplicantView",
    permission: "users_applicant_view",
  },

  {
    component: Profile,
    path: "/profile",
    exact: true,
    title: "Profile",
    permission: "profile",
  },
  {
    component: Reload,
    path: "/reload",
    exact: true,
    title: "Reload",
    permission: "dashboard",
  },
  {
    component: Pricing,
    path: "/pricing",
    title: "Narx belgilash",
    permission: "davaktiv_g_value",
  },
].map((route) => ({
  ...route,
  path: `/home${route.path}`,
  id: Math.random() + new Date().getTime(),
}))
