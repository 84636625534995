import { useSelector } from "react-redux"
import AlertElement from "./AlertElement"
import "./style.scss"

const AlertComponent = ({ propAlert }) => {
  const alerts = useSelector((state) => state.alert.alerts)
  console.log(alerts)
  return (
    <div className="alerts fixed right-5 top-5 ">
      {propAlert?.map((alert) => (
        <AlertElement
          key={alert.id}
          id={alert.id}
          title={alert.title}
          type={alert.type}
        />
      ))}
      {alerts.map((alert) => (
        <AlertElement
          key={alert.id}
          id={alert.id}
          title={alert.title}
          type={alert.type}
        />
      ))}
    </div>
  )
}

export default AlertComponent
